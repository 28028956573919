import { graphql } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import { BusinessHero } from "../components/BusinessHero";
import { BusinessHowWeWork } from "../components/BusinessHowWeWork";
import { BusinessIssues } from "../components/BusinessIssues";
import { BusinessSolutions } from "../components/BusinessSolutions";
import { Layout } from "../components/Layout";
import { Maintenance } from "../components/Maintenance";

const BusinessPageTemplate = ({ layout, ...props }) => {
  return (
    <>
      <Helmet>
        <title>{props.seo.title}</title>
        <meta name="Description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keywords} />
      </Helmet>

      <BusinessHero {...props.hero} />
      <BusinessIssues {...props.issues} />
      <BusinessSolutions {...props.solutions} />
      <BusinessHowWeWork pageId={props.pageId} {...props.howWeWork} />
      {layout.maintenance && <Maintenance {...layout.maintenance} secondary />}
    </>
  );
};

BusinessPageTemplate.getLivePreviewData = (props) => ({
  ...props.data,
  ...Layout.getLivePreviewData(props),
  ...BusinessHero.getLivePreviewData(props),
  ...BusinessIssues.getLivePreviewData(props),
  ...BusinessSolutions.getLivePreviewData(props),
  ...BusinessHowWeWork.getLivePreviewData(props),
});

const BusinessPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout
      {...data.layout.frontmatter}
      pageId={frontmatter.pageId}
      path={frontmatter.path}
    >
      <BusinessPageTemplate layout={data.layout.frontmatter} {...frontmatter} />
    </Layout>
  );
};

const pageQuery = graphql`
  query BusinessPageTemplate {
    layout: markdownRemark(frontmatter: { meta: { eq: "layout" } }) {
      frontmatter {
        ...LayoutFragment
        ...MaintenanceFragment
      }
    }

    markdownRemark(frontmatter: { templateKey: { eq: "business-page" } }) {
      frontmatter {
        pageId
        path
        seo {
          title
          description
          keywords
        }
        templateKey
        ...BusinessHeroFragment
        ...BusinessIssuesFragment
        ...BusinessSolutionsFragment
        ...BusinessHowWeWorkFragment
      }
    }
  }
`;

export default BusinessPage;

export { pageQuery, BusinessPageTemplate };
