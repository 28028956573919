import { graphql, Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import React from "react";
import { Button } from "../Button";
import "./styles.css";

const BusinessHero = ({ background, title, text, buttonLabel }) => {
  return (
    <BackgroundImage
      className="BusinessHero"
      fluid={background.childImageSharp.fluid}
    >
      <div className="[ Content md:Content_small sm:Content_medium ]">
        <div className="BusinessHero__content">
          <h1 className="[ Color_darkBlue Font_headerMiddle sm:Font_headerSmallMiddle ]">
            {title}
          </h1>
          <p className="[ BusinessHero__text ] [ Color_darkBlue Font_bigText sm:Font_smallRegularText ]">
            {text}
          </p>
          <Link to="/business/#contact-us">
            <Button className="BusinessHero__button">{buttonLabel}</Button>
          </Link>
        </div>
      </div>
    </BackgroundImage>
  );
};

BusinessHero.getLivePreviewData = ({ data, getAsset }) => ({
  hero: {
    ...data.hero,
    background: {
      childImageSharp: {
        fluid: {
          src: getAsset(data.hero.background).url,
        },
      },
    },
  },
});

const businessHeroQuery = graphql`
  fragment BusinessHeroFragment on MarkdownRemarkFrontmatter {
    hero {
      background {
        childImageSharp {
          fluid(maxWidth: 2880, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      title
      text
      buttonLabel
    }
  }
`;

export { BusinessHero, businessHeroQuery };
