import { graphql } from "gatsby";
import React from "react";
import { Solutions } from "../Solutions";
import "./styles.css";

const BusinessSolutions = ({
  background,
  title,
  name,
  subtitle,
  systems,
  solutions,
}) => {
  return (
    <Solutions
      background={background}
      title={title}
      name={name}
      subtitle={subtitle}
      systems={systems}
      solutions={solutions}
    />
  );
};

BusinessSolutions.getLivePreviewData = ({ data, getAsset }) => ({
  solutions: {
    ...data.solutions,
    background: getAsset(data.solutions.background).url,
    solutions: data.solutions.solutions.map((solution) => ({
      ...solution,
      photo: getAsset(solution.photo).url,
    })),
  },
});

const businessSolutionsQuery = graphql`
  fragment BusinessSolutionsFragment on MarkdownRemarkFrontmatter {
    solutions {
      background {
        childImageSharp {
          fluid(maxWidth: 1544) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      title
      name
      subtitle
      systems
      solutions {
        photo {
          childImageSharp {
            fluid(maxWidth: 936) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
        text
        price
      }
    }
  }
`;

export { BusinessSolutions, businessSolutionsQuery };
