import { graphql } from "gatsby";
import React from "react";
import { Issues } from "../Issues";

import "./styles.css";

const BusinessIssues = ({ background, title, text, issues }) => {
  return (
    <Issues
      className="BusinessIssues"
      background={background}
      title={title}
      text={text}
      issues={issues}
    />
  );
};

BusinessIssues.getLivePreviewData = ({ data, getAsset }) => ({
  issues: {
    ...data.issues,
    background: getAsset(data.issues.background).url,
    issues: data.issues.issues.map(issue => ({
      ...issue,
      icon: {
        publicURL: getAsset(issue.icon).url
      }
    }))
  }
});

const businessIssuesQuery = graphql`
  fragment BusinessIssuesFragment on MarkdownRemarkFrontmatter {
    issues {
      background {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      title
      text

      issues {
        icon {
          publicURL
        }
        title
        text
      }
    }
  }
`;

export { BusinessIssues, businessIssuesQuery };
